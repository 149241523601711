.navbar[data-v-d16d6306] {
  height: 64px;
  overflow: hidden;
  position: relative;
  background: #19ba95;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.navbar .hamburger-container[data-v-d16d6306] {
    line-height: 60px;
    height: 100%;
    float: left;
    cursor: pointer;
    -webkit-transition: background .3s;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;
    color: white;
}
.navbar .hamburger-container[data-v-d16d6306]:hover {
      background: rgba(0, 0, 0, 0.025);
}
.navbar .breadcrumb-container[data-v-d16d6306] {
    float: left;
    line-height: 64px;
}
.navbar .errLog-container[data-v-d16d6306] {
    display: inline-block;
    vertical-align: top;
}
.navbar .right-menu[data-v-d16d6306] {
    float: right;
    height: 100%;
    line-height: 64px;
}
.navbar .right-menu[data-v-d16d6306]:focus {
      outline: none;
}
.navbar .right-menu .right-menu-item[data-v-d16d6306] {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: white;
      vertical-align: text-bottom;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-d16d6306] {
        cursor: pointer;
        -webkit-transition: background .3s;
        transition: background .3s;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-d16d6306]:hover {
          background: rgba(0, 0, 0, 0.025);
}
.navbar .right-menu .avatar-container[data-v-d16d6306] {
      margin-right: 30px;
}
.navbar .right-menu .avatar-container .avatar-wrapper[data-v-d16d6306] {
        margin-top: 15px;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        line-height: normal;
        padding: 2px;
        overflow: hidden;
}
.navbar .right-menu .avatar-container .avatar-wrapper .user-avatar[data-v-d16d6306] {
          cursor: pointer;
          width: 100%;
          height: 100%;
          border-radius: 50%;
}
.navbar .right-menu .avatar-container .avatar-wrapper .el-icon-caret-bottom[data-v-d16d6306] {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
}
