.app-main[data-v-078753dd] {
  /* 50= navbar  50  */
  min-height: calc(100vh - 64px);
  width: 100%;
  position: relative;
  overflow: auto;
  background-color: #f0f2f5;
}
.fixed-header + .app-main[data-v-078753dd] {
  padding-top: 64px;
}
.hasTagsView .app-main[data-v-078753dd] {
  /* 84 = navbar + tags-view = 50 + 34 */
  min-height: calc(100vh - 84px);
}
.hasTagsView .fixed-header + .app-main[data-v-078753dd] {
  padding-top: 84px;
}
