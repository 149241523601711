@charset "UTF-8";

/*fade*/
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s; }

.fade-enter,
.fade-leave-active {
  opacity: 0; }

/*fade-transform*/
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all .5s;
  transition: all .5s; }

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px); }

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px); }

/*breadcrumb transition*/
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all .5s;
  transition: all .5s; }

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px); }

.breadcrumb-move {
  -webkit-transition: all .5s;
  transition: all .5s; }

.breadcrumb-leave-active {
  position: absolute; }

#app .main-container {
  min-height: 100%;
  -webkit-transition: margin-left .28s;
  transition: margin-left .28s;
  margin-left: 250px;
  position: relative; }

#app .sidebar-container {
  font-weight: 500;
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  width: 250px !important;
  background-color: #fff;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden; }
  #app .sidebar-container .horizontal-collapse-transition {
    -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
    transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out; }
  #app .sidebar-container .scrollbar-wrapper {
    overflow-x: hidden !important; }
  #app .sidebar-container .el-scrollbar__bar.is-vertical {
    right: 0px; }
  #app .sidebar-container .el-scrollbar {
    height: 100%; }
  #app .sidebar-container.has-logo .el-scrollbar {
    height: calc(100% - 50px); }
  #app .sidebar-container .is-horizontal {
    display: none; }
  #app .sidebar-container a {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-decoration: none; }
  #app .sidebar-container .svg-icon {
    margin-right: 5px;
    font-size: 18px;
    color: #000; }
  #app .sidebar-container .el-menu {
    border: none;
    height: 100%;
    width: 100% !important; }
  #app .sidebar-container .submenu-title-noDropdown:hover,
  #app .sidebar-container .el-submenu__title:hover {
    background-color: rgba(240, 242, 245, 0.7) !important; }
  #app .sidebar-container .is-active > .el-submenu__title {
    color: #000 !important; }
  #app .sidebar-container .nest-menu .el-submenu > .el-submenu__title,
  #app .sidebar-container .el-submenu .el-menu-item {
    color: #000 !important;
    min-width: 250px !important; }
    #app .sidebar-container .nest-menu .el-submenu > .el-submenu__title:hover,
    #app .sidebar-container .el-submenu .el-menu-item:hover {
      background-color: rgba(240, 242, 245, 0.7) !important; }
  #app .sidebar-container .el-menu-item.is-active {
    background-color: rgba(24, 144, 255, 0.2) !important; }
    #app .sidebar-container .el-menu-item.is-active:hover {
      background-color: rgba(24, 144, 255, 0.2) !important;
      color: rgba(24, 144, 255, 0.7) !important; }
    #app .sidebar-container .el-menu-item.is-active {
      border-right: 3px solid #1890ff !important; }

#app .hideSidebar .sidebar-container {
  width: 54px !important; }

#app .hideSidebar .main-container {
  margin-left: 54px; }

#app .hideSidebar .svg-icon {
  margin-right: 0px; }

#app .hideSidebar .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative; }
  #app .hideSidebar .submenu-title-noDropdown .el-tooltip {
    padding: 0 !important; }
    #app .hideSidebar .submenu-title-noDropdown .el-tooltip .svg-icon {
      margin-left: 20px; }

#app .hideSidebar .el-submenu {
  overflow: hidden; }
  #app .hideSidebar .el-submenu > .el-submenu__title {
    padding: 0 !important; }
    #app .hideSidebar .el-submenu > .el-submenu__title .svg-icon {
      margin-left: 20px; }
    #app .hideSidebar .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
      display: none; }

#app .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block; }

#app .el-menu--collapse .el-menu .el-submenu {
  min-width: 250px !important; }

#app .mobile .main-container {
  margin-left: 0px; }

#app .mobile .sidebar-container {
  -webkit-transition: -webkit-transform .28s;
  transition: -webkit-transform .28s;
  transition: transform .28s;
  transition: transform .28s, -webkit-transform .28s;
  width: 250px !important; }

#app .mobile.hideSidebar .sidebar-container {
  pointer-events: none;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transform: translate3d(-250px, 0, 0);
          transform: translate3d(-250px, 0, 0); }

#app .withoutAnimation .main-container,
#app .withoutAnimation .sidebar-container {
  -webkit-transition: none;
  transition: none; }

.el-menu--vertical > .el-menu .svg-icon {
  margin-right: 16px; }

.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title:hover,
.el-menu--vertical .el-menu-item:hover {
  background-color: rgba(240, 242, 245, 0.7) !important; }

.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar-track-piece {
    background: #d3dce6; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar {
    width: 6px; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar-thumb {
    background: #99a9bf;
    border-radius: 20px; }

.el-breadcrumb__inner {
  color: #fff;
  font-weight: normal; }

.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
  color: #fff;
  font-weight: normal; }
  .el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover {
    color: rgba(255, 255, 255, 0.9); }

.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #fff; }

.el-breadcrumb__separator {
  color: #fff; }

.el-table th > .cell {
  text-overflow: clip;
  white-space: pre-line; }

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed, select:-internal-autofill-selected {
  background-color: #fff !important; }

.el-table th {
  background-color: #f5f5f5; }

.el-table td, .el-table th.is-leaf {
  border-bottom-color: #d8d8d8; }

.el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right-color: #d8d8d8;
  border-color: #d8d8d8 !important; }

.el-table--border, .el-table--group {
  border-color: #d8d8d8; }

.el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right-color: #d8d8d8; }

table {
  border-color: #d8d8d8 !important;
  border-right-color: #d8d8d8 !important; }

.el-table--border::after, .el-table--group::after, .el-table::before {
  background-color: #d8d8d8; }

.el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
  padding-left: 0; }

.el-table .cell, .el-table th div {
  padding-right: 0; }

.el-table thead {
  color: #505050;
  font-weight: 600; }

.search_form .el-form-item__label {
  color: #505050;
  font-weight: 600; }

.el-input {
  max-width: 500px; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "PingFang SC", "\5FAE\8F6F\96C5\9ED1", "Microsoft YaHei", Helvetica Neue, Helvetica;
  font-weight: 500; }

.app-container {
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  min-height: calc(100vh - 134px); }

.table-container {
  margin-top: 30px; }
  .table-container .table-opera .svg-icon {
    color: #cacaca; }
    .table-container .table-opera .svg-icon:hover {
      cursor: pointer; }

.pagination-container {
  padding-top: 35px;
  width: 100%;
  margin: 0 -20px;
  margin-bottom: 40px;
  background-color: #fff; }
  .pagination-container .el-pagination {
    float: right; }

.footer-container {
  width: 100%;
  text-align: center;
  margin: auto; }

.text-left {
  float: left; }

.remote-select_right {
  float: right;
  color: #8492a6;
  font-size: 13px; }

.text-nowrap {
  white-space: nowrap; }

.bg-purple {
  background-color: #7b88ef; }

.bg-green {
  background-color: #19ba95; }

.bg-orage {
  background-color: #ff9e8c; }

.bg-blue {
  background-color: #647fbbf7; }

.m-t-30 {
  margin-top: 30px; }

.m-t-20 {
  margin-top: 20px; }

.m-l-30 {
  margin-left: 30px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-5 {
  margin-right: 5px; }

.m-l-8 {
  margin-left: 8px; }

a:-webkit-any-link {
  text-decoration: none !important; }

@media all and (max-width: 768px) {
  .app-container {
    margin: 0;
    padding: 10px; }
  .el-dialog {
    width: 95%; }
  .el-message-box {
    width: 95%; }
  .date-picker_width {
    width: 350px !important; } }
